import Vue from 'vue'
import { getInfo, login, logout, refreshToken } from '@/api/auth'
import {
  getLogin,
  getRefreshToken,
  getToken,
  getUID,
  getUserName,
  removeRefreshToken,
  removeToken,
  removeUID,
  removeUserName,
  setLogin,
  setRefreshToken,
  setToken,
  setUID,
  setUserName
} from '@/utils/auth'
import { setWatermark } from '@/utils/watermark'
import router, { resetRouter } from '@/router'

const state = {
  isLoging: getLogin(),
  token: getToken(),
  name: getUserName() || '',
  avatar: '',
  introduction: '',
  roles: [],
  loginTips: false,
  refreshToken: getRefreshToken(),
  userId: getUID()
}

const mutations = {
  SET_ISLOGING: (state, loging) => {
    state.isLoging = loging
    setLogin(loging)
  },
  SET_TOKEN: (state, token) => {
    setToken(token)
    state.token = token
  },
  SET_REFRESH_TOKEN: (state, token) => {
    setRefreshToken(token)
    state.refreshToken = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    setUserName(name)
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_UID: (state, uid) => {
    state.userId = uid
  },
  // 设置弹出提示框
  OPEN_LOGIN_MODEL: (state, flag) => {
    state.loginTips = flag
  },
  SET_WATERMARK: (state, user) => {
    if (process.env.VUE_APP_ENV === 'PROD') {
      if (user.userName && user.userId) {
        setWatermark(user.userName + '  ' + user.userId)
      }
    }
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password, clientCode, captchaValue, captchaKey } = userInfo
    return new Promise((resolve, reject) => {
      login({
        loginAccount: username.trim(),
        password,
        clientCode,
        captchaValue: captchaValue.trim().trimEnd(),
        captchaKey
      })
        .then((data) => {
          const { loginToken, user, refreshToken } = data
          commit('SET_UID', user.userId)
          commit('SET_TOKEN', loginToken)
          commit('SET_REFRESH_TOKEN', refreshToken)
          commit('SET_NAME', user.userName)
          setUID(user.userId)
          setToken(loginToken)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo({ id: state.userId })
        .then((data) => {
          if (!data) {
            reject(new Error('Verification failed, please Login again.'))
          }
          commit('SET_UID', data.userId)
          commit('SET_NAME', data.userName)
          commit('SET_WATERMARK', data)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user logout
  logout({ state, commit }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(async() => {
          commit('SET_NAME', '')
          commit('SET_UID', '')
          commit('SET_TOKEN', '')
          commit('permission/CLEAR_ROUTES', null, { root: true })
          removeUID()
          removeToken()
          removeRefreshToken()
          removeUserName()
          Vue.prototype.$callWorker.port.postMessage({ type: 'logout' })
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 刷新token
  resetToken({ state, commit }) {
    return new Promise((resolve, reject) => {
      refreshToken({ refreshToken: state.refreshToken })
        .then((data) => {
          commit('SET_TOKEN', data.token)
          commit('SET_REFRESH_TOKEN', data.refreshToken)
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
