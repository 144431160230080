<template>
  <el-dialog :show-close="false" :visible="errorLogs" append-to-body center title="提示" width="400px">
    <p class="danger-tips">登录已过期，请重新登录</p>
    <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="goLogin">去登录</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { removeCtiLogin } from '@/utils/auth'

export default {
  components: {},
  props: {},
  computed: {
    errorLogs() {
      return this.$store.state.user.loginTips
    }
  },
  data() {
    return {}
  },
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    // 跳转到登录
    async goLogin() {
      await this.$store.dispatch('user/logout')
      await this.$store.commit('user/OPEN_LOGIN_MODEL', false)
      this.$callWorker.port.postMessage({ type: 'logout' })
      removeCtiLogin()
      if (this.$store.state.user.isLoging) {
        window.location.href = process.env.VUE_APP_LOGIN_URL + `?redirect=${window.location.href}`
      } else {
        this.$router.replace('/login')
      }
      // this.$router.replace('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.danger-tips {
  text-align: center;
  font-size: 18px;
}
</style>
