import {
  start,
  registerMicroApps,
  addErrorHandler,
  initGlobalState,
  setDefaultMountApp
} from 'qiankun'
import { Notification } from 'element-ui'
import state from './state'
import formatApps from './sub-apps'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css' // progress bar style
import store from '@/store'

// local test Code S
// const testApps = [
//   {
//     clientId: 1,
//     code: 'dy:cms:pointline',
//     deleted: 0,
//     description: '点线中心应用入口',
//     exnInfo: { activationRule: '/dy-cms-pointline', applicationName: 'dyCmsPointline' },
//     iconUrl: '132334',
//     container: '#vue', // 容器名
//     id: 21281000000096,
//     name: '点线中心-本地',
//     parentId: 0,
//     sequence: 2,
//     type: 4,
//     url: 'localhost:11006'
//   },
//   {
//     clientId: 1,
//     code: 'dy:cms:SYSTEM',
//     deleted: 0,
//     description: '系统中心应用入口',
//     exnInfo: { activationRule: '/dy-cms-system', applicationName: 'dyCmsSystem' },
//     iconUrl: '132334',
//     id: 21281000000075,
//     name: '系统中心-本地',
//     parentId: 0,
//     sequence: 1,
//     type: 4,
//     url: 'localhost:12010'
//   },
//   {
//     clientId: 1,
//     code: 'dy:cms:csc',
//     description: '客服中心应用入口',
//     exnInfo: { activationRule: '/dy-cms-csc', applicationName: 'dyCmsCsc' },
//     iconUrl: '132334',
//     id: '21281000000088',
//     name: '客服中心-本地',
//     parentId: '0',
//     sequence: 99,
//     type: 4,
//     url: 'localhost:12012'
//   }
// ]
// local test Code E
NProgress.configure({ showSpinner: false })
export default async () => {
  // 获取并处理一级菜单

  // local test code S
  // const apps = formatApps(testApps)
  // local test code E

  const data = await store.dispatch('permission/getRootMenu')
  const apps = formatApps(data)
  store.dispatch('permission/setRootRoutes', apps)

  registerMicroApps(apps, {
    beforeLoad(e) {
      store.dispatch('permission/setRoutes', [])
      NProgress.start()
    },
    async beforeMount() {
      store.dispatch('permission/setRoutes', [])
      NProgress.start()
    },
    afterMount() {
      NProgress.done()
    },
    beforeUnmount() {
      store.dispatch('permission/setRoutes', [])
    }
  })

  const actions = initGlobalState(state)
  actions.onGlobalStateChange((state, prev) => {
    const { subMenuList, visitedViews, type } = state
    if (type === 'init') {
      store.commit('tagsView/SET_CLICK_FLAG', true)
    }
    // 设置子应用菜单
    store.dispatch('permission/setRoutes', subMenuList)
    // 设置访问历史
    if (visitedViews) {
      store.dispatch('tagsView/setVisitedViews', visitedViews)
    }
  }, true)

  addErrorHandler((event) => {
    Notification.error({
      title: '哦豁~',
      message: event
    })
  })
  start({
    prefetch: false,
    sandbox: true
  })
  if (apps[0]) setDefaultMountApp(apps[0].activeRule)
}
