const state = {
  visitedViews: [],
  clickFlag: true
}

const mutations = {
  SET_VISITED_VIEWS: (state, views) => {
    state.visitedViews = views
  },
  SET_CLICK_FLAG: (state, views) => {
    state.clickFlag = views
  }
}

const actions = {
  setVisitedViews({ commit }, views) {
    commit('SET_VISITED_VIEWS', views)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
