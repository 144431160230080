<template>
  <div id="app" v-loading="subappLoading">
    <transition name="fade">
      <router-view v-if="isMainPage"/>
    </transition>
    <transition name="fade">
      <layout v-if="!isMainPage">
        <div id="QIANKUN_CONTAINER" class="qiankun-container"></div>
      </layout>
    </transition>
  </div>
</template>

<script>
import { whiteList } from '@/router'
import layout from '@/layout'
import startMicroApp from '@/micro'
import { mapGetters } from 'vuex'
import Watermark from '@/utils/watermark/index.js'
export default {
  name: 'App',
  components: {
    layout
  },
  data() {
    return {
      isDing: false
    }
  },
  computed: {
    isMainPage () {
      return whiteList.indexOf(this.$route.name) >= 0 || this.isDing
    },
    ...mapGetters(['subappLoading', 'userId'])
  },
  methods: {
    startMicroApp () {
      if (this.userId && !this.isMainPage) {
        this.$nextTick(() => {
          startMicroApp()
        })
      }
    }
  },
  watch: {
    isMainPage () {
      this.startMicroApp()
    },
    $route: {
      handler(to, from) {
        if (to && to.path === '/ding-driver-account') {
          this.isDing = true
          this.$router.push(to)
        } else if (from && from.path === '/ding-driver-account') {
          this.isDing = true
          this.$router.push(from)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    const script = document.createElement('script')
    script.src = process.env.VUE_APP_BASE_EDIT + '/web-apps/apps/api/documents/api.js'
    document.head.appendChild(script)
    this.startMicroApp()
    if (process.env.VUE_APP_ENV === 'PROD') {
      this.$nextTick(() => {
        Watermark()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.qiankun-container {
  min-height: 100vh;
  background: $bgColor;
  min-width: 1300px;
  overflow-x: auto;
}
.fixed-header+.qiankun-container {
  padding-top: 50px;
}

.hasTagsView {
  .app-main-fundation {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header+.qiankun-container {
    padding-top: 84px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
