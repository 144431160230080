import $micro from './emit'
import store from '@/store'
const container = '#QIANKUN_CONTAINER'

const BASE_URL = process.env.VUE_APP_BASE_URL

export default (menus) => {
  const apps = menus.map((item) => {
    return {
      container,
      path: item.exnInfo.activationRule,
      title: item.name,
      name: item.exnInfo.applicationName,
      icon: item.iconUrl,
      entry: '//' + item.url,
      permission: item.code,
      activeRule: item.exnInfo.activationRule,
      props: {
        $micro,
        store,
        BASE_URL,
        APP_ID: item.id,
        ACTIVE_RULE: item.exnInfo.activationRule + '/'
      }
    }
  })

  return apps
}
