<template>
  <el-dialog
    title="修改密码"
    :visible.sync="dialogVisible"
    width="30%"
    :modal-append-to-body="false"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-width="formLabelWidth"
      label-suffix="："
    >
      <el-form-item label="原密码" prop="oldPassword">
        <el-input v-model="form.oldPassword" type="password" clearable></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input v-model="form.newPassword" type="password" clearable></el-input>
      </el-form-item>
      <el-form-item label="再次输入新密码" prop="newPassword2">
        <el-input v-model="form.newPassword2" type="password" clearable></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { changePassword } from '@/api/auth'
export default {
  props: { visible: { type: Boolean, default: false } },
  data() {
    const validNewPwd = (rule, val, callback) => {
      if (val === '') {
        callback(new Error('请输入新密码'))
      } else if (val.length < 6) {
        callback(new Error('密码最少6位'))
      } else {
        callback()
      }
    }
    const validNewPwd2 = (rule, val, callback) => {
      if (val === '') {
        callback(new Error('请再次输入新密码'))
      } else if (val !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      formLabelWidth: '160px',
      form: {
        oldPassword: '',
        newPassword: '',
        newPassword2: ''
      },
      rules: {
        oldPassword: [{ message: '请输入原密码', required: true }],
        newPassword: [{ validator: validNewPwd, required: true }],
        newPassword2: [{ validator: validNewPwd2, required: true }]
      }
    }
  },
  watch: {
    visible(val) {
      this.dialogVisible = val
    },
    dialogVisible(val) {
      if (!val) {
        this.$refs.form.resetFields()
        this.$emit('close')
      }
    }
  },
  methods: {
    /**
     * 提交修改密码
     */
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const res = await changePassword(this.form)
          if (res) {
            this.dialogVisible = false
            await this.$store.dispatch('user/logout')
            this.$router.push('/login')
            this.$message({
              type: 'success',
              message: '修改成功，请重新登录!'
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
