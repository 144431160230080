const currentEnv = process.env.VUE_APP_ENV
const TokenKey = currentEnv + 'FOUNDATIONTOKENKEY'
const RefreshTokenKey = currentEnv + 'FOUNDATIONREFRESHTOKENKEY'
const UIDKEY = currentEnv + 'FOUNDATIONUIDKEY'
const userNameKey = currentEnv + 'USERNAMEKEY'
const loginKey = currentEnv + 'LOGIN'
const CTI_LOGIN_DATA_KEY = 'ctiLoginData'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function getLogin() {
  return localStorage.getItem(loginKey)
}

export function setLogin(loging) {
  return localStorage.setItem(loginKey, loging)
}

export function getRefreshToken() {
  return localStorage.getItem(RefreshTokenKey)
}

export function setRefreshToken(token) {
  return localStorage.setItem(RefreshTokenKey, token)
}

export function removeRefreshToken() {
  return localStorage.removeItem(RefreshTokenKey)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

export function getUID() {
  return localStorage.getItem(UIDKEY)
}

export function setUID(UID) {
  return localStorage.setItem(UIDKEY, UID)
}

export function removeUID() {
  return localStorage.removeItem(UIDKEY)
}

export function getUserName() {
  return localStorage.getItem(userNameKey)
}

export function setUserName(name) {
  return localStorage.setItem(userNameKey, name)
}

export function removeUserName() {
  return localStorage.removeItem(userNameKey)
}

export function getCtiLogin() {
  return localStorage.getItem(CTI_LOGIN_DATA_KEY)
}

export function setCtiLogin(val) {
  localStorage.setItem(CTI_LOGIN_DATA_KEY, val)
}

export function removeCtiLogin() {
  localStorage.removeItem(CTI_LOGIN_DATA_KEY)
}
