<template>
  <div class="sub-apps-menu">
    <!-- <el-menu
      mode="horizontal"
      :router="true"
      :default-active="$route.path"
      :active-text-color="theme"
    >
      <el-menu-item :index="`/app${index + 1}`" v-for="(item, index) in subAppsList" :key="index">
        <i class="el-icon-location"></i>
        <span>{{item.name}}{{index + 1}}</span>
      </el-menu-item>
    </el-menu> -->
    <el-tabs class="sub-app-list" type="card" :value="activePath">
      <el-tab-pane
        v-for="(item, index) in subAppsList"
        :key="index"
        :name="item.path"
        class="sub-app-item"
      >
        <!-- <router-link
          slot="label"
          :to="item.path"
          :title="item.name"
          :class="{ active: item.path == activePath }"
          v-bind:style="{ backgroundColor: item.path == activePath ? theme : '' }"
        > -->
        <a
          slot="label"
          @click="changeProject(item.path)"
          :title="item.name"
          :class="{ active: item.path == activePath }"
          v-bind:style="{ backgroundColor: item.path == activePath ? theme : '' }"
        >
          <i :class="item.icon | iconFilter"></i>
          <span>{{ item.title }}</span>
        </a>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SubApps',
  computed: {
    ...mapGetters(['theme', 'subAppsList']),
    activePath() {
      const url = (this.$route.path || window.location.pathname).split('/')[1] || ''

      return `/${url}`
    }
  },
  filters: {
    iconFilter(val) {
      if (val.startsWith('el-')) {
        return val
      } else {
        return 'iconfont ' + val
      }
    }
  },
  methods: {
    /**
       * 点击切换项目
       * @param { String } val
       */
    changeProject(val) {
      if (this.$store.state.tagsView.clickFlag) {
        // 当点击当前选中不做处理，点其他项目的时候才处理跳转和清除tab
        if (!this.$route.path.includes(val)) {
          this.$store.commit('tagsView/SET_CLICK_FLAG', false)
          this.$store.dispatch('tagsView/setVisitedViews', [])
          this.$router.push(val)
          window.location.reload()
        }
      } else {
        this.$notify.warning({
          title: '哦豁~',
          message: '请等待子应用加载完成后再点击'
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .sub-apps-menu {
    width: 100%;
    height: 46px;
    line-height: 40px;
    display: flex;
    align-items: center;

    .sub-app-list {
      width: 98%;
      margin: 0 20px;

      .el-tabs__header {
        margin-bottom: 0px;
      }

      .el-tabs__nav,
      .el-tabs__header,
      .el-tabs__item {
        padding: 0px !important;
        border: none !important;
      }

      .el-tabs__item {
        margin-right: 4px;

        i {
          font-size: 18px;
          margin-right: 8px;
          transition: all 0.33s cubic-bezier(0.35, 1.06, 0.69, 1.02);
        }

        a {
          display: flex;
          height: 40px;
          line-height: 40px;
          padding: 0 16px;
          font-size: 14px;
          border-radius: 4px;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: #eee;
          }

          &.active {
            font-weight: bold;
            position: relative;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            color: #fff;
            .iconfont {
              font-weight: normal;
            }
          }
        }
      }
    }
  }
</style>
