import { Loading } from 'element-ui'
let requestCount = 0 // 当前正在请求的数量
let loadingInstance
const options = {
  background: 'rgba(0,0,0,.5)',
  spinner: 'el-icon-loading',
  text: '加载中'
}
/**
 * 显示loading
 */
export const showLoading = () => {
  requestCount = requestCount < 0 ? 0 : requestCount
  if (requestCount === 0) {
    loadingInstance = Loading.service(options)
  }
  requestCount++
}
/**
 * 隐藏loading
 */
export const hideLoading = () => {
  requestCount--
  if (requestCount === 0) {
    loadingInstance.close()
  }
}
