import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken, setUID } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
// import startMicroApp from '@/micro'
// import formatApps from '@/micro/sub-apps'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect', '/ding-driver-account'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)
  if (to.query.token) {
    store.commit('user/SET_ISLOGING', true)
    store.commit('user/SET_TOKEN', to.query.token)
    store.commit('user/SET_REFRESH_TOKEN', to.query.token)
    store.commit('user/SET_UID', to.query.userId)
    setUID(to.query.userId)
    store.dispatch('user/getInfo')
    next()
  }

  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {
    // startMicroApp()
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      window.location.href = process.env.VUE_APP_LOGIN_URL + `?redirect=${window.location.href}`
      // next({ path: '/' })
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else if (to.path === '/ding-driver-account') {
      next()
      NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        next()
      } else {
        try {
          next()
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/logout')
          // if (store.state.user.isLoging) {
          //   window.location.href = process.env.VUE_APP_LOGIN_URL + `?redirect=${window.location.href}`
          // } else {
          //   next(`/login?redirect=${to.path}`)
          //   NProgress.done()
          // }
          window.location.href = process.env.VUE_APP_LOGIN_URL + `?redirect=${window.location.href}`
          store.commit('user/SET_ISLOGING', false)
        }
      }
    }
  } else {
    // console.log(whiteList)
    /* has no token */
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      if (to.path === '/login') {
        window.location.href = process.env.VUE_APP_LOGIN_URL + `?redirect=${window.location.href}`
      }
      // next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      // if (store.state.user.isLoging) {
      //   window.location.href = process.env.VUE_APP_LOGIN_URL + `?redirect=${window.location.href}`
      // } else {
      //   next(`/login?redirect=${to.path}`)
      //   NProgress.done()
      // }
      window.location.href = process.env.VUE_APP_LOGIN_URL + `?redirect=${window.location.href}`
      store.commit('user/SET_ISLOGING', false)
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})
