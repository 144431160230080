import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layout */
// import Layout from '@/layout'

// 注：官方vue-router@3.0及以上新版本路由默认回调返回的都是promise，原先旧版本的路由回调将废弃！！！！
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const constantRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login')
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "404" */ '../views/errors/404')
  },
  {
    path: '/ding-driver-account',
    name: 'ding',
    component: () => import(/* webpackChunkName: "ding" */'../views/ding/index')
  }
]

const createRouter = () => new VueRouter({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export const whiteList = ['Login', 'NotFound', 'ding']

export default router
