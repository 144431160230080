// import { getRootMenu } from '@/api/user'
import { getRootMenu } from '@/api/auth'
// import router, { resetRouter } from '@/router'
const state = {
  routes: [],
  rootRoutes: [],
  addRoutes: [],
  routeObj: {}
}

const mutations = {
  CLEAR_ROUTES: (state) => {
    state.routes = []
  },
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = routes
  },
  SET_ROOT_ROUTES: (state, routes) => {
    state.rootRoutes = routes
  },
  SET_ROOT_OBJ: (state, routes) => {
    state.routeObj = { ...state.routeObj, ...routes }
  }
}

const actions = {
  setRoutes ({ commit }, routes) {
    commit('SET_ROUTES', routes)
  },
  setRootRoutes ({ commit }, menu) {
    commit('SET_ROOT_ROUTES', menu)
  },
  setActiveMenu ({ commit }, menu) {
    commit('SET_ACTIVE_MENU', menu)
  },
  getRootMenu () {
    return new Promise((resolve, reject) => {
      getRootMenu().then((data) => {
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
