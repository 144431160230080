import { requestFn as request } from '@/utils/request'

export function login(data = {}) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

export function getRootMenu() {
  return request({
    url: '/auth/menu/listByPCode',
    method: 'post',
    data: {
      parentCode: 'menuTop0'
    }
  })
}

/**
 * 刷新token
 * @param { Object } val
 * @return
 */

export function refreshToken(data) {
  return request({
    url: '/refreshToken',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

/**
 * 获取用户信息
 * @param {*} data
 * @returns
 */
export function getInfo(data) {
  return request({
    url: '/syscenter/user/retrieve',
    method: 'post',
    data
  })
}

/**
 * 修改密码
 * @param {*} data
 * @returns
 */
export function changePassword(data) {
  return request({
    url: '/changePassword',
    method: 'post',
    data
  })
}

/**
 * 获取验证码图片
 * @returns
 */
export function createCaptcha() {
  return request({
    url: '/createCaptcha',
    method: 'get'
  })
}
