import watermark from './watermark'
import user from '@/store/modules/user'

const callback = function(mutationsList, observer) {
  for (const mutation of mutationsList) {
    if (mutation.target.id === '1.23452384164.123412416' || mutation?.removedNodes[0]?.id === '1.23452384164.123412416' || mutation?.removedNodes[1]?.id === '1.23452384164.123412416') {
      watermark.set(user.state.name + '  ' + user.state.userId)
    }
  }
}

// 观察器实例 防止输出水印 elment
const observer = new MutationObserver(callback)

// 观察器的配置（需要观察什么变动）
const config = { attributes: true, subtree: true, childList: true }

const Watermark = function() {
  let curr = 0
  const fun = function() {
    if (user.state.name && user.state.userId) {
      observer.disconnect()
      watermark.set(user.state.name + '  ' + user.state.userId)
      // 开始观察
      observer.observe(document.body, config)
    } else {
      if (curr < 10) {
        setTimeout(() => {
          curr++
          fun()
          console.log('Redraw watermark!')
        }, 1000)
      }
    }
  }
  fun()
}
export const setWatermark = function (user) {
  observer.disconnect()
  watermark.set(user)
  // 开始观察
  observer.observe(document.body, config)
}

export const delWatermark = function() {
  // 停止观察
  observer.disconnect()
  watermark.del()
}

export default Watermark
