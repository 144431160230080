// import Vue from 'vue'
import axios from 'axios'
import { Notification } from 'element-ui'
import store from '@/store'
// import router from '@/router'
import { showLoading, hideLoading } from './axios/httpLoading'
import { CodeMessage } from './axios/codeMessage'
// import { requestQueue } from './axios/requestQueue'
// export function requestQueue(list) {
//   return list
// }
let requestQueue = []
/**
 *@className: DYRequest
 *@description: http请求
 *@author: Youn
 *@date: 2021-07-04 09:51:43
 *@version: v1.0.0
 */
class DYRequest {
  _instance
  _contentType
  isRfreshing = false // 控制刷新 token 的状态
  constructor(baseURL, contentType) {
    this._instance = axios.create({
      baseURL: baseURL,
      timeout: 1000000,
      headers: {
        contentType
      }
    })
    this._setInterceptors(this._instance)
  }

  /**
   * 设置拦截器
   * @param { Object } instance
   */
  _setInterceptors(instance) {
    instance.interceptors.request.use(
      (config) => {
        const token = store.state.user.token
        // 如果 token 存在
        // 让每个请求携带自定义 token 请根据实际情况自行修改
        if (token) {
          config.headers.authorization = token
        }
        return config
      },
      (error) => {
        Notification.error({
          title: '哦豁~',
          message: '请求失败啦'
        })
        return Promise.reject(error)
      }
    )

    instance.interceptors.response.use(
      (response) => {
        // 对响应数据做点什么
        if (response.data.code === '401') {
          // 刷新 token
          if (!this.isRfreshing) {
            this.isRfreshing = true // 开启刷新状态
            // 尝试刷新获取新的 token
            store
              .dispatch('user/resetToken')
              .then(() => {
                // 成功后把之前请求失败的接口重新调一遍
                for (const callback of requestQueue) {
                  callback()
                }
                // 清空队列
                requestQueue = []
              })
              .catch(() => {
                // 需要退出登录才能跳转到登录，有token会自动跳转到首页
                // store.dispatch('user/logout').then(() => {
                //   router.replace('/login')
                // })
                // 修改逻辑，刷新token失效后通知parent弹出去登录提示框
                store.commit('user/OPEN_LOGIN_MODEL', true)
              })
              .finally(() => {
                this.isRfreshing = false
              })
            // 刷新状态下，把请求挂起放到 requests 数组中
            return new Promise((resolve) => {
              if (response.config.url !== '/refreshToken' || response.config.url !== '/logout') {
                requestQueue.push(() => {
                  resolve(instance(response.config))
                })
              }
            })
            // }
          }
          return Promise.reject(response.data)
        } else {
          return Promise.resolve(response.data)
        }
      },
      (error) => {
        const response = error.response
        // 超时处理
        if (error.message.includes('timeout')) {
          Notification.error({
            title: '哦豁~',
            message: '请求超时啦'
          })
        }
        // 响应错误码提示
        if (response?.status) {
          Notification.error({
            title: '哦豁~',
            message: CodeMessage[error.response.status]
          })
        }
        // 断网处理  跳转到断网页面
        if (!window.navigator.onLine) {
          Notification.error({
            title: '哦豁~',
            message: '网络链接失败'
          })
        }
        return Promise.reject(response)
      }
    )
  }

  /**
   * axios 请求方法返回data中内容
   * @param {string} url 地址
   * @param {Object} params 参数
   * @param {Object} data 参数
   * @param {boolean} isLoading 是否要显示loading
   * @param {string | null | undefined} successMsg 成功的消息 只有有值的时候会提示
   * @param {string | null | undefined | 'no'} errorMsg 错误消息 只有有值的时候会提示
   * @param {'get' | 'post'} method 成功的消息 null和undefined不显示
   */
  requstAll({ url, params, data, isLoading, successMsg, errorMsg, method }) {
    const key = method === 'post' ? 'data' : 'params'
    if (isLoading) {
      showLoading()
    }
    return new Promise((resolve, reject) => {
      this._instance({
        method,
        url,
        [key]: params || data
      })
        .then((res) => {
          if (isLoading) {
            hideLoading()
          }
          this._showError(res, successMsg, errorMsg)
          if (res.code === '000000') {
            resolve(res.data)
          } else {
            reject(res.msg)
          }
        })
        .catch((err) => {
          if (isLoading) {
            hideLoading()
          }
          reject(err)
        })
    })
  }

  /**
   * 错误处理
   * @param {Object} data 返回的data
   * @param {string | null | undefined} successMsg 成功的消息 只有有值的时候会提示
   * @param {string | null | undefined | 'no'} errorMsg 错误消息 只有有值的时候会提示
   */
  _showError(data, successMsg, errorMsg) {
    const { code } = data
    // 显示传入的接口成功的提示消息
    if (code === '000000' && successMsg) {
      Notification.success(successMsg)
    }
    // 显示错误信息
    if (code === '-1' && errorMsg !== 'no') {
      Notification.error({
        title: '哦豁~',
        message: errorMsg || data.msg
      })
    }
  }
}

const request = new DYRequest(
  localStorage.getItem('APP_BASE_URL') || process.env.VUE_APP_BASE_URL,
  'application/json'
)
const requstOther = new DYRequest(process.env.VUE_APP_BASE_URL, 'application/json')
// const requstOtherFormdata = new DYRequest(process.env.VUE_APP_BASE_URL2,'application/x-www-form-urlencoded' )
const requestAll = (data) => request.requstAll(data)
export const requestFn = (data) => requstOther.requstAll(data)

export default requestAll
