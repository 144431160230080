/**
 * @name 事件
 */
import store from '@/store'
// import router from '@/router'
import Vue from 'vue'

export default {
  async logout () {
    store.commit('user/OPEN_LOGIN_MODEL', true)
    // await store.dispatch('user/logout')
    // router.replace('/login')
  },
  bus: new Vue()
}
